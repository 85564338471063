import {
  BigBanners,
  SmallBanners,
  MerchantShowcase,
} from "@app/domains/merchant/views";
import { MerchantLayout } from "@app/domains/merchant/layout";
import { MerchantResponse } from "@app/domains/merchant/models";
import { withMerchantData } from "@app/domains/merchant/shared";
import { EmptyState as PomodoroEmptyState } from "@app/domains/shared/design-system";
import { GetServerSideProps } from "next";
import NextImage from "next/image";
import { useEffect } from "react";
import styled from "styled-components";

import { Catalog, CatalogResponse } from "@app/domains/catalog/models";
import { List, CarouselCategory } from "@app/domains/catalog/views";
import { catalogAboyeur } from "@app/domains/catalog/events";

import {
  CatalogFeatureToggles,
  getCatalogFeatureToggles,
} from "@app/domains/catalog/utils/ssr";
import { Flex } from "@ifood/pomodoro-components";
import { useRouter } from "next/router";
import { appAboyeur } from "@app/domains/shared/config";

const EmptyState = styled(PomodoroEmptyState)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 42px;

  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: center;
`;

type Props = {
  featureToggles?: CatalogFeatureToggles;
  merchantResponse?: MerchantResponse;
  catalogResponse?: CatalogResponse;
  serverError?: string;
  merchantPath?: string;
  isCanaryModeEnabled?: boolean;
};

const HomePage: React.VFC<Props> = ({
  merchantPath,
  merchantResponse,
  catalogResponse,
  featureToggles,
  isCanaryModeEnabled = false,
}) => {
  const hasCatalog = Boolean(catalogResponse?.categories.length);
  const router = useRouter();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const redirectParam = urlParams.get("redirectSiteMercado");

    if (redirectParam) {
      appAboyeur.events.redirect.siteMercado();
      urlParams.delete("redirectSiteMercado");
      const newUrl = location.pathname;
      router.replace(newUrl, undefined, { shallow: true });
    }
  }, [router]);

  useEffect(() => {
    if (hasCatalog) return;
    catalogAboyeur.events.catalog.empty();
  }, [hasCatalog]);

  return (
    <MerchantLayout
      pageName="home"
      pageTitle="Início"
      merchantPath={merchantPath}
      merchantResponse={merchantResponse}
      catalogResponse={catalogResponse}
      featureToggles={featureToggles}
      isCanaryModeEnabled={isCanaryModeEnabled}
    >
      <Flex
        flexDirection="column"
        margin="0 auto"
        gap="clamp(32px, 4dvw, 48px)"
      >
        <MerchantShowcase />
        <BigBanners />
        <SmallBanners />
        <CarouselCategory />
        {hasCatalog && <List />}
        {!hasCatalog && (
          <EmptyState
            title="Nada por aqui"
            description="Parece que não temos itens disponíveis no momento."
          >
            <NextImage
              src="/images/error.png"
              alt=""
              width={291}
              height={286}
              layout="fixed"
            />
          </EmptyState>
        )}
      </Flex>
    </MerchantLayout>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = withMerchantData(
  async (context) => {
    const { path, merchantResponse } = context.merchantData;
    const { catalogGroup } = merchantResponse.contextSetup;
    const { uuid } = merchantResponse;
    const { accessToken } = context;

    const catalogResponse = await Catalog.getCatalog(
      uuid,
      catalogGroup,
      accessToken,
    );

    return {
      props: {
        merchantPath: path,
        merchantResponse,
        catalogResponse,
        featureToggles: getCatalogFeatureToggles(merchantResponse),
        isCanaryModeEnabled: Boolean(context.req.headers["x-ifood-canary"]),
      },
    };
  },
);

export default HomePage;
